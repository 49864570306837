import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Download, Send, CheckCircle, Key } from 'lucide-react';
import { api } from '../../services/api';

interface InvoicePreviewModalProps {
    isOpen: boolean;
    onClose: () => void;
    pdfData: string | null;
    documentId?: string | null;
    invoiceInfo?: {
        cliente?: string;
        fecha?: string;
        total?: number;
        subtotal?: number;
        iva?: number;
    };
}

export function InvoicePreviewModal({ isOpen, onClose, pdfData, documentId, invoiceInfo }: InvoicePreviewModalProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [certificatePassword, setCertificatePassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const [emittedInvoiceData, setEmittedInvoiceData] = useState<{
        pdfUrl?: string;
        pdfContent?: string;
        folio?: string;
    } | null>(null);

    if (!isOpen) return null;

    const handleEmit = () => {
        if (!documentId) return;
        setShowPasswordModal(true);
    };

    const handleSignAndIssue = async () => {
        if (!documentId) return;

        setIsLoading(true);
        setError(null);

        try {
            const entityId = localStorage.getItem('selectedEntityId');
            if (!entityId) {
                throw new Error('No hay entidad seleccionada');
            }

            // Prepare the request body for batch-issue
            const requestBody = {
                document_ids: [documentId],
                digital_certificate_password: certificatePassword
            };

            // Use the batch-issue endpoint
            const response = await api.post(`/master-entities/${entityId}/documents/batch-issue/`, requestBody);
            const data = await response.json();

            // Check if the process was successful
            if (data && (data.success || (data.results && data.results.length > 0))) {
                // Get the result of the first document (since we only sent one)
                const documentResult = data.results?.[0] || {};

                if (documentResult.error) {
                    throw new Error(documentResult.error);
                }

                // Fix for duplicated data:application/pdf;base64 prefix
                let pdfContent = documentResult.pdf_data;
                if (pdfContent && pdfContent.startsWith('data:application/pdf;base64,data:application/pdf;base64,')) {
                    pdfContent = pdfContent.replace('data:application/pdf;base64,', '');
                }

                // Clean localStorage
                localStorage.removeItem('draftDocumentId');
                localStorage.removeItem('invoiceFormData');

                // Set success state and data
                setEmittedInvoiceData({
                    pdfUrl: documentResult.pdf_url,
                    pdfContent: pdfContent,
                    folio: documentResult.folio
                });
                setSuccess(true);
                setShowPasswordModal(false);
            } else {
                // Show specific error message
                const errorMessage = data.error || 'El documento no se pudo emitir correctamente';
                throw new Error(errorMessage);
            }
        } catch (error: any) {
            console.error('Error al emitir documento:', error);
            setError(error.message || 'Error al emitir el documento');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4"
            >
                <motion.div
                    initial={{ scale: 0.95, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.95, opacity: 0 }}
                    className="bg-white rounded-2xl shadow-xl max-w-4xl w-full max-h-[90vh] overflow-hidden"
                >
                    <div className="p-6 border-b border-gray-100">
                        <div className="flex items-center justify-between mb-2">
                            <h3 className="text-xl font-semibold text-gray-900">
                                Previsualización de Factura
                            </h3>
                            <button
                                onClick={onClose}
                                className="text-gray-400 hover:text-gray-600 transition-colors"
                            >
                                <X className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="text-sm text-gray-500">
                            Borrador de documento - Pendiente de emisión
                        </div>
                    </div>

                    <div className="p-6 overflow-y-auto max-h-[60vh]">
                        {invoiceInfo && Object.keys(invoiceInfo).length > 0 && (
                            <div className="mb-4 grid grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg">
                                <div>
                                    <p className="text-xs text-gray-500 uppercase tracking-wide mb-1">Cliente</p>
                                    <p className="font-medium text-gray-900">{invoiceInfo.cliente || 'No especificado'}</p>
                                </div>
                                <div>
                                    <p className="text-xs text-gray-500 uppercase tracking-wide mb-1">Fecha</p>
                                    <p className="font-medium text-gray-900">{invoiceInfo.fecha || new Date().toLocaleDateString('es-CL')}</p>
                                </div>
                                <div>
                                    <p className="text-xs text-gray-500 uppercase tracking-wide mb-1">Subtotal</p>
                                    <p className="font-medium text-gray-900">${typeof invoiceInfo.subtotal === 'number' ? invoiceInfo.subtotal.toLocaleString('es-CL') : '0'}</p>
                                </div>
                                <div>
                                    <p className="text-xs text-gray-500 uppercase tracking-wide mb-1">IVA (19%)</p>
                                    <p className="font-medium text-gray-900">${typeof invoiceInfo.iva === 'number' ? invoiceInfo.iva.toLocaleString('es-CL') : '0'}</p>
                                </div>
                                <div className="col-span-2 border-t border-gray-200 pt-2 mt-2">
                                    <p className="text-xs text-gray-500 uppercase tracking-wide mb-1">Total con IVA</p>
                                    <p className="font-medium text-gray-900 text-lg">${typeof invoiceInfo.total === 'number' ? invoiceInfo.total.toLocaleString('es-CL') : '0'}</p>
                                </div>
                            </div>
                        )}

                        {success && emittedInvoiceData ? (
                            <div className="flex flex-col items-center justify-center py-12 text-center">
                                <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mb-6">
                                    <CheckCircle className="h-10 w-10 text-green-600" />
                                </div>
                                <h3 className="text-2xl font-bold text-gray-900 mb-2">
                                    ¡Felicitaciones!
                                </h3>
                                <p className="text-lg text-gray-600 mb-6">
                                    Tu Pana te emitió la factura #{emittedInvoiceData.folio}
                                </p>
                                {emittedInvoiceData.pdfContent && (
                                    <iframe
                                        src={emittedInvoiceData.pdfContent.startsWith('data:application/pdf;base64,')
                                            ? emittedInvoiceData.pdfContent
                                            : `data:application/pdf;base64,${emittedInvoiceData.pdfContent}`}
                                        className="w-full h-[400px] border-0 rounded-lg mb-6"
                                        title="Factura emitida"
                                    />
                                )}
                                <a
                                    href={emittedInvoiceData.pdfUrl || (emittedInvoiceData.pdfContent?.startsWith('data:application/pdf;base64,')
                                        ? emittedInvoiceData.pdfContent
                                        : `data:application/pdf;base64,${emittedInvoiceData.pdfContent}`)}
                                    download={`factura_${emittedInvoiceData.folio}.pdf`}
                                    className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors flex items-center space-x-2"
                                >
                                    <Download className="h-5 w-5" />
                                    <span>Descargar Factura</span>
                                </a>
                            </div>
                        ) : pdfData ? (
                            <iframe
                                src={pdfData}
                                className="w-full h-[600px] border-0 rounded-lg"
                                title="Previsualización de factura"
                            />
                        ) : (
                            <div className="flex items-center justify-center h-[600px] bg-gray-50 rounded-lg border border-gray-200">
                                <p className="text-gray-500">No hay datos de previsualización disponibles</p>
                            </div>
                        )}
                    </div>

                    <div className="p-6 border-t border-gray-100 bg-gray-50">
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={onClose}
                                className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors rounded-md"
                                disabled={isLoading || success}
                            >
                                {success ? 'Cerrar' : 'Cancelar'}
                            </button>
                            {pdfData && !success && (
                                <div className="flex space-x-2">
                                    <a
                                        href={pdfData}
                                        download="factura.pdf"
                                        className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors flex items-center space-x-2"
                                    >
                                        <Download className="h-4 w-4" />
                                        <span>Descargar PDF</span>
                                    </a>
                                    <button
                                        onClick={handleEmit}
                                        disabled={isLoading}
                                        className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors flex items-center space-x-2 disabled:bg-green-400"
                                    >
                                        <Send className="h-4 w-4" />
                                        <span>Emitir Factura</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </motion.div>

                {/* Password Modal */}
                {showPasswordModal && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-[60] p-4"
                    >
                        <motion.div
                            initial={{ scale: 0.95, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.95, opacity: 0 }}
                            className="bg-white rounded-xl shadow-xl max-w-md w-full overflow-hidden"
                        >
                            <div className="p-6 border-b border-gray-100">
                                <h3 className="text-xl font-semibold text-gray-900">
                                    Contraseña del Certificado Digital
                                </h3>
                            </div>

                            <div className="p-6">
                                <p className="text-gray-600 mb-4">
                                    Para emitir la factura, necesitamos la contraseña de tu certificado digital.
                                </p>

                                <div className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Contraseña
                                        </label>
                                        <div className="relative">
                                            <Key className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                                            <input
                                                type="password"
                                                value={certificatePassword}
                                                onChange={(e) => setCertificatePassword(e.target.value)}
                                                className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                                placeholder="Ingresa la contraseña"
                                            />
                                        </div>
                                    </div>

                                    {error && (
                                        <div className="p-3 bg-red-50 text-red-700 rounded-md text-sm">
                                            {error}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="p-6 border-t border-gray-100 bg-gray-50 flex justify-end space-x-3">
                                <button
                                    onClick={() => setShowPasswordModal(false)}
                                    className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors rounded-md"
                                    disabled={isLoading}
                                >
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleSignAndIssue}
                                    disabled={!certificatePassword || isLoading}
                                    className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors flex items-center space-x-2 disabled:bg-green-400"
                                >
                                    {isLoading ? (
                                        <>
                                            <span className="h-4 w-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                                            <span>Procesando...</span>
                                        </>
                                    ) : (
                                        <>
                                            <Send className="h-4 w-4" />
                                            <span>Emitir Factura</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </motion.div>
        </AnimatePresence>
    );
}
import React from 'react';
import { motion } from 'framer-motion';

export function LoadingCircle() {
    return (
        <div className="flex items-center justify-start">
            <motion.div
                initial={{ scale: 0.3 }}
                animate={{
                    scale: [0.3, 0.6, 0.3]
                }}
                transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: "easeInOut"
                }}
                className="w-1.5 h-1.5 bg-gray-400 rounded-full"
            />
        </div>
    );
}

export { LoadingCircle as default };
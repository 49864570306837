import React, { useState } from 'react';
import { FileText, Building2, MapPin, Briefcase, Eye, Send } from 'lucide-react';
import { InvoicePreviewModal } from './InvoicePreviewModal';
import { api } from '../../services/api';

interface InvoiceData {
    emisor_id?: number;
    rut_receptor?: string;
    razon_social?: string | null;
    productos?: Array<{
        item_name: string;
        quantity: number;
        unit_price: number;
        item_total: number;
    }>;
    direccion?: {
        address: string;
        district: string;
        city: string;
        id?: string;
    } | null;
    actividad?: {
        name: string;
        code: string;
        id?: string;
    } | null;
    fecha_emision?: string;
    contacto?: string | null;
    pdf_data?: string | null;
}

interface InvoicePreviewProps {
    data: InvoiceData;
    onPreview: () => void;
    onEmit: () => void;
}

export function InvoicePreview({ data, onPreview, onEmit }: InvoicePreviewProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfData, setPdfData] = useState<string | null>(null);
    const [documentId, setDocumentId] = useState<string | null>(null);
    const [totalNeto, setTotalNeto] = useState(0);
    const [iva, setIva] = useState(0);
    const [totalConIva, setTotalConIva] = useState(0);

    if (!data || !data.rut_receptor) return null;

    // Check if all required fields are present
    const isComplete =
        data.rut_receptor &&
        data.razon_social &&
        data.actividad?.name &&
        data.direccion?.address &&
        data.productos &&
        data.productos.length > 0;

    // Get missing fields for tooltip
    const getMissingFields = () => {
        const missing = [];
        if (!data.rut_receptor) missing.push('RUT del cliente');
        if (!data.razon_social) missing.push('Razón social');
        if (!data.actividad?.name) missing.push('Actividad económica');
        if (!data.direccion?.address) missing.push('Dirección');
        if (!data.productos || data.productos.length === 0) missing.push('Al menos un producto');

        return missing.join(', ');
    };

    // Calculate totals
    React.useEffect(() => {
        if (data.productos && data.productos.length > 0) {
            const neto = data.productos.reduce((sum, item) => sum + (item.item_total || 0), 0);
            const calculatedIva = Math.round(neto * 0.19);
            const total = neto + calculatedIva;

            setTotalNeto(neto);
            setIva(calculatedIva);
            setTotalConIva(total);
        }
    }, [data.productos]);

    const handlePreview = async () => {
        if (!isComplete) return;

        setIsLoading(true);
        try {
            // Get the entity ID from localStorage
            const entityId = localStorage.getItem('selectedEntityId');
            if (!entityId) {
                throw new Error('No hay entidad seleccionada');
            }

            // Prepare the document data
            const documentData = {
                date_issued: data.fecha_emision || new Date().toISOString().split('T')[0],
                purchase_transaction_type: "1",
                sale_transaction_type: "1",
                payment_method: "1",
                due_date: data.fecha_emision || new Date().toISOString().split('T')[0],
                dte_type: "1033", // Factura electrónica
                receiver_rut: data.rut_receptor,
                receiver_business_name: data.razon_social || '',
                receiver_business_activity: data.actividad?.name || '',
                receiver_activity_code: data.actividad?.code || '',
                receiver_contact: data.contacto || '',
                receiver_address: data.direccion?.address || '',
                receiver_district: data.direccion?.district || '',
                receiver_city: data.direccion?.city || '',
                details: data.productos?.map(product => ({
                    item_name: product.item_name,
                    item_description: '',
                    quantity: product.quantity,
                    unit_price: product.unit_price,
                    item_total: product.item_total,
                    item_code: '',
                    item_type_code: '',
                    unit: 'UN',
                    discount_percent: 0,
                    other_tax: ''
                })) || []
            };

            // Create the document
            const response = await api.post(`/master-entities/${entityId}/documents_v2/`, documentData);

            if (!response.ok) {
                throw new Error('Error al crear el documento');
            }

            const responseData = await response.json();
            setDocumentId(responseData.id);

            // Get the PDF
            const pdfResponse = await api.get(`/master-entities/${entityId}/documents/${responseData.id}/?pdf_file=true`);

            if (!pdfResponse.ok) {
                throw new Error('Error al obtener el PDF');
            }

            const pdfData = await pdfResponse.json();

            if (pdfData.pdf_base64) {
                setPdfData(`data:application/pdf;base64,${pdfData.pdf_base64}`);
            } else {
                throw new Error('No se pudo obtener el PDF del documento');
            }

            // Open the modal
            setIsModalOpen(true);
            if (onPreview) {
                onPreview();
            }
        } catch (error) {
            console.error('Error al previsualizar:', error);
            alert('Error al generar la previsualización. Por favor, intenta nuevamente.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleEmit = async () => {
        if (!documentId) {
            // If no document ID, create it first
            await handlePreview();
        }

        if (onEmit) {
            onEmit();
        }
    };

    return (
        <>
            <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden max-h-[400px] max-w-[320px] flex flex-col">
                <div className="p-3 bg-gradient-to-br from-green-50 to-white border-b border-gray-200 flex-shrink-0">
                    <div className="flex items-center space-x-2 text-green-600">
                        <FileText className="h-5 w-5" />
                        <h3 className="font-medium">Vista Previa de Factura</h3>
                    </div>
                </div>

                <div className="p-3 space-y-3 overflow-y-auto">
                    {/* Datos del Receptor */}
                    <div>
                        <div className="flex items-center space-x-2 mb-1 text-gray-600">
                            <Building2 className="h-4 w-4" />
                            <h4 className="text-sm font-medium">Datos del Cliente</h4>
                        </div>
                        <div className="bg-gray-50 rounded-lg p-3 space-y-2">
                            <div>
                                <span className="text-sm text-gray-500">RUT:</span>
                                <span className="ml-2 font-medium">{data.rut_receptor}</span>
                            </div>
                            {data.razon_social && (
                                <div>
                                    <span className="text-sm text-gray-500">Razón Social:</span>
                                    <span className="ml-2">{data.razon_social}</span>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Dirección */}
                    {data.direccion && (
                        <div>
                            <div className="flex items-center space-x-2 mb-1 text-gray-600">
                                <MapPin className="h-4 w-4" />
                                <h4 className="text-sm font-medium">Dirección</h4>
                            </div>
                            <div className="bg-gray-50 rounded-lg p-3">
                                <div className="space-y-1">
                                    <div className="font-medium">{data.direccion.address}</div>
                                    <div className="text-sm text-gray-600">
                                        {data.direccion.district}, {data.direccion.city}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Actividad Económica */}
                    {data.actividad && (
                        <div>
                            <div className="flex items-center space-x-2 mb-1 text-gray-600">
                                <Briefcase className="h-4 w-4" />
                                <h4 className="text-sm font-medium">Actividad Económica</h4>
                            </div>
                            <div className="bg-gray-50 rounded-lg p-3">
                                <div className="font-medium">{data.actividad.name}</div>
                            </div>
                        </div>
                    )}

                    {/* Productos */}
                    {data.productos && data.productos.length > 0 && (
                        <div>
                            <div className="flex items-center justify-between mb-1">
                                <h4 className="text-sm font-medium text-gray-600">Detalle</h4>
                            </div>
                            <div className="overflow-x-auto rounded-lg border border-gray-200">
                                <table className="w-full divide-y divide-gray-200" style={{ minWidth: '280px' }}>
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500">Descripción</th>
                                            <th className="px-3 py-2 text-right text-xs font-medium text-gray-500">Cant.</th>
                                            <th className="px-3 py-2 text-right text-xs font-medium text-gray-500">Precio</th>
                                            <th className="px-3 py-2 text-right text-xs font-medium text-gray-500">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {data.productos.map((item, index) => (
                                            <tr key={index}>
                                                <td className="px-3 py-2 text-sm truncate max-w-[140px]">{item.item_name}</td>
                                                <td className="px-3 py-2 text-sm text-right">{item.quantity}</td>
                                                <td className="px-3 py-2 text-sm text-right">
                                                    ${typeof item.unit_price === 'number' ? item.unit_price.toLocaleString('es-CL') : '0'}
                                                </td>
                                                <td className="px-3 py-2 text-sm text-right">
                                                    ${typeof item.item_total === 'number' ? item.item_total.toLocaleString('es-CL') : '0'}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Totals */}
                            <div className="mt-2 bg-gray-50 rounded-lg p-2">
                                <div className="flex justify-between text-sm">
                                    <span className="text-gray-600">Subtotal:</span>
                                    <span className="font-medium">${totalNeto.toLocaleString('es-CL')}</span>
                                </div>
                                <div className="flex justify-between text-sm">
                                    <span className="text-gray-600">IVA (19%):</span>
                                    <span className="font-medium">${iva.toLocaleString('es-CL')}</span>
                                </div>
                                <div className="flex justify-between text-sm font-bold border-t border-gray-200 mt-1 pt-1">
                                    <span>Total:</span>
                                    <span>${totalConIva.toLocaleString('es-CL')}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="p-2 bg-gray-50 border-t border-gray-200 flex justify-end space-x-2 flex-shrink-0">
                    <button
                        onClick={isComplete ? handlePreview : undefined}
                        disabled={!isComplete || isLoading}
                        className={`flex items-center space-x-1.5 px-4 py-2 rounded-md text-sm transition-colors relative group
              ${isComplete ? 'bg-green-600 text-white hover:bg-green-700' : 'bg-gray-300 text-gray-500'} 
              ${isLoading ? 'opacity-70 cursor-wait' : ''}`}
                        title={!isComplete ? `Faltan campos: ${getMissingFields()}` : ''}
                    >
                        <Eye className="h-4 w-4" />
                        <span>{isLoading ? (
                            <span className="flex items-center">
                                <span className="h-4 w-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></span>
                                Cargando...
                            </span>
                        ) : 'Previsualizar'}</span>

                        {/* Tooltip for incomplete data */}
                        {!isComplete && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                                Faltan: {getMissingFields()}
                            </div>
                        )}
                    </button>
                </div>
            </div>

            <InvoicePreviewModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                pdfData={pdfData}
                documentId={documentId}
                invoiceInfo={{
                    cliente: data.razon_social || data.rut_receptor,
                    fecha: data.fecha_emision,
                    subtotal: totalNeto,
                    iva: iva,
                    total: totalConIva
                }}
            />
        </>
    );
}
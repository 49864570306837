import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Download } from 'lucide-react';
import { Invoice } from '../../types/invoice';

interface PreviewModalProps {
    isOpen: boolean;
    invoice: Invoice | null;
    invoices: Invoice[] | null;
    onClose: () => void;
}

export function PreviewModal({ isOpen, invoice, invoices, onClose }: PreviewModalProps) {
    if (!invoice && !invoices) return null;
    if (!isOpen) return null;

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4"
            >
                <motion.div
                    initial={{ scale: 0.95, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.95, opacity: 0 }}
                    className="bg-white rounded-2xl shadow-xl max-w-2xl w-full max-h-[90vh] overflow-hidden"
                >
                    <div className="p-6 border-b border-gray-100">
                        <div className="flex items-center justify-between mb-2">
                            <h3 className="text-xl font-semibold text-gray-900">
                                {invoice ? 'Previsualización de Factura' : 'Previsualización Masiva'}
                            </h3>
                            <button
                                onClick={onClose}
                                className="text-gray-400 hover:text-gray-600 transition-colors"
                            >
                                <X className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="text-sm text-gray-500">
                            Borrador de documento - Pendiente de emisión
                        </div>
                    </div>

                    <div className="p-6 overflow-y-auto">
                        {invoice ? (
                            <div className="space-y-6">
                                <div className="grid grid-cols-2 gap-6 bg-gray-50 p-4 rounded-lg">
                                    <div>
                                        <p className="text-xs text-gray-500 uppercase tracking-wide mb-1">Cliente</p>
                                        <p className="font-medium text-gray-900">{invoice.cliente}</p>
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-500 uppercase tracking-wide mb-1">Fecha</p>
                                        <p className="font-medium text-gray-900">{invoice.fecha}</p>
                                    </div>
                                </div>

                                <div>
                                    <p className="text-xs text-gray-500 uppercase tracking-wide mb-3">Detalle</p>
                                    <div className="bg-gray-50 rounded-lg overflow-hidden border border-gray-200">
                                        <table className="w-full">
                                            <thead>
                                                <tr className="bg-gray-100">
                                                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Descripción</th>
                                                    <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase">Cant.</th>
                                                    <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase">Precio</th>
                                                    <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice.items.map((item, idx) => (
                                                    <tr key={idx} className="border-t border-gray-200">
                                                        <td className="px-4 py-2.5 text-sm text-gray-900">{item.descripcion}</td>
                                                        <td className="px-4 py-2.5 text-sm text-right text-gray-900">{item.cantidad}</td>
                                                        <td className="px-4 py-2.5 text-sm text-right text-gray-900">
                                                            ${item.precio_unitario.toLocaleString()}
                                                        </td>
                                                        <td className="px-4 py-2.5 text-sm text-right font-medium text-gray-900">
                                                            ${item.total.toLocaleString()}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr className="border-t border-gray-200">
                                                    <td colSpan={3} className="px-4 py-2 text-sm text-right font-medium">Subtotal:</td>
                                                    <td className="px-4 py-2 text-sm text-right font-bold text-gray-900">
                                                        ${typeof invoice.total === 'number' ? invoice.total.toLocaleString() : '0'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3} className="px-4 py-2 text-sm text-right font-medium">IVA (19%):</td>
                                                    <td className="px-4 py-2 text-sm text-right font-bold text-gray-900">
                                                        ${typeof invoice.total === 'number' ? Math.round(invoice.total * 0.19).toLocaleString() : '0'}
                                                    </td>
                                                </tr>
                                                <tr className="border-t border-gray-200 bg-gray-50">
                                                    <td colSpan={3} className="px-4 py-2 text-sm text-right font-medium">Total con IVA:</td>
                                                    <td className="px-4 py-2 text-sm text-right font-bold text-gray-900">
                                                        ${typeof invoice.total === 'number' ? Math.round(invoice.total * 1.19).toLocaleString() : '0'}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="bg-gray-50 rounded-lg overflow-hidden">
                                <table className="w-full">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="px-4 py-2 text-left text-sm">ID</th>
                                            <th className="px-4 py-2 text-left text-sm">Cliente</th>
                                            <th className="px-4 py-2 text-right text-sm">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoices?.map((inv) => (
                                            <tr key={inv.id} className="border-t border-gray-200">
                                                <td className="px-4 py-2 text-sm">{inv.id}</td>
                                                <td className="px-4 py-2 text-sm">{inv.cliente}</td>
                                                <td className="px-4 py-2 text-sm text-right">
                                                    ${typeof inv.total === 'number' ? inv.total.toLocaleString() : '0'}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>

                    <div className="p-6 border-t border-gray-100 bg-gray-50">
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={onClose}
                                className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors rounded-md"
                            >
                                Cancelar
                            </button>
                            <div className="flex space-x-2">
                                <button className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors flex items-center space-x-2">
                                    <Download className="h-4 w-4" />
                                    <span>Descargar PDF</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
}